<template>
    <div>
        <v-card :loading="loading" class="mx-auto" max-width="374" rounded="" elevation="0">
            <template slot="progress">
                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
            </template>

            <v-img height="250" :src="getImageLink(card.image)">
                <div class="d-flex justify-space-between">
                <v-chip class="mt-2 ml-2 primary white--text text-uppercase">{{ card.language }}</v-chip>

                <v-chip class="mt-2 mr-2 error white--text text-capitalize" @click="$emit('delete', card)">
                    Supprimer
                </v-chip>
                </div>
                
            </v-img>
            <v-divider class="mx-4"></v-divider>
            <v-card-actions>
                <v-btn color="primary" text @click="$emit('edit', card)">
                    Modifier
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn icon @click="show = !show">
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-expand-transition>
                <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>
                       

                            <v-chip small class="ma-2" close color="gifty white--text" text-color="white" @click:close="() => { }"  v-for="category in card.categories" :key="category.id" >
                                {{ category.name }}
                            </v-chip>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
export default {
    props: ["card"],
    data: () => ({
        show: false,
        loading :false
    }),

}
</script>

<style lang="scss" scoped></style>