<template>
    <v-container fluid>
        <div class="main">
            <div class="d-flex justify-space-between">
                <div></div>
                <h3 class="mb-4">Cartes d'occasions</h3>
            </div>

            <div class="d-flex justify-space-between mb-5">
                
                    <div>

                        <v-btn class="white--text ml-4" color="grey darken-4" depressed exact to="/occasion_cards/categories">
                        <v-icon right class="mr-2">mdi-cog</v-icon>
                        Catégories
                    </v-btn>

                    <v-btn class="white--text ml-4" color="grey darken-4" depressed exact 
                        @click="openFilter()"
                    >
                        <v-icon right class="mr-2">mdi-filter</v-icon>
                        Filtrage
                    </v-btn>

                    </div>
                    <v-btn class="white--text ml-4" color="gifty" depressed exact @click="addCard()">
                        <v-icon right class="mr-2">mdi-plus</v-icon>
                        Nouvelle carte
                    </v-btn>
                </div>
            

            <v-row v-if="loading" class="mt-4">
                
                <v-col cols="4" sm="12" md="3" lg="3" v-for="index in (1, 8)" :key="index">
                    <v-skeleton-loader type="card"/>
                </v-col>
                    
            </v-row>

            <v-row v-else>

                <v-col cols="12">

                    <v-row >
                        <v-col cols="4" sm="12" md="3" lg="3" v-for="card in occasion_cards.data" :key="card.id">
                            <card-item :card="card" @edit="($event) => editCard($event)" @delete="($event) =>  deleteCard($event)"/>
                        </v-col>
                    </v-row>

                    <v-card class="rounded-lg shadow mt-5" v-if="occasion_cards.data.length == 0">

                        <v-card-text >
                            <div class="text-center">
                                <v-avatar tile size="200">
                                    <v-img :src="require('@/assets/database.svg')"></v-img>
                                </v-avatar>
                                <p>Aucun enregistrement</p>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <div v-if="occasion_cards.data && occasion_cards.data.length > 0" class="mt-1 d-flex justify-space-between">
                    <div :style="{ width: '80px' }">
                        <v-select outlined v-model="filter.size" dense :items="[5, 10, 20, 30, 40, 50]"
                            @change="fetchData"></v-select>
                    </div>

                    <v-pagination total-visible="6" v-if="occasion_cards.total > filter.size" circle v-model="filter.page"
                        :length="Math.ceil(occasion_cards.total / filter.size)" @input="fetchData"
                        hide-details></v-pagination>
                </div>
                </v-col>
            </v-row>
            <ConfirmDelete ref="deleteCard" @refresh="fetchData" />
            <FormCard ref="formCard" @refresh="fetchData()"/>
            <FilterDialog ref="filterDialog" @handleFilter="fetchData()" :filter="filter" />
        </div>
    </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import ConfirmDelete from './ConfirmDelete.vue';
import CardItem from './CardItem.vue';
import FormCard from './FormCard.vue';
import FilterDialog from './FilterDialog.vue';


export default {
    components: { ConfirmDelete, CardItem, FormCard, FilterDialog },
    data() {
        return {
            occasion_cards: {},
            loading: true,
            filter: {
                page: 1,
                size: 10
            }
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            HTTP.get("v1/occasion_cards", {
                params: {
                    ...this.filter
                }
            }).then((res) => {
                this.occasion_cards = res.data.data;
                this.loading = false;

            }).catch((e) => {
                this.loading = false;
                console.log(e);
            });
        },

    
        addCard(){
            this.$refs.formCard.open();
        },
        openFilter(){
            this.$refs.filterDialog.open();
        },
        editCard(item){
            this.$refs.formCard.open(item);
        },
        deleteCard(item){
            this.$refs.deleteCard.open(item);
        }

    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped></style>