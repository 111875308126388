<template>
    <div>
      <v-dialog v-model="dialog" max-width="600" persistent>
        <v-card class="text-center">
  
          <v-card-title>
            <v-spacer />
            <v-icon @click="notConfirmed">mdi-close</v-icon>
          </v-card-title>
  
          <v-card-text v-if="card">
  
            <v-alert v-if="error" border="left" color="error" dark dismissible>
              {{ error }}
            </v-alert>
  
            <v-icon color="primary" size="50">
                mdi-help-rhombus
            </v-icon>
  
            <h2 class="text--primary mt-3">CONFIRMATION</h2>
  
            <p class="pt-5 font-weight-medium">
              Voulez-vous vraiment supprimer cette Carte
            </p>
  
            <v-row>
              <v-col>
                <v-btn class="rounded-lg mt-4" color="primary" depressed large @click="[confirm()]" :loading="btnLoading">
                  <v-icon left>mdi-info</v-icon>
                  Confirmer mon action
                </v-btn>
              </v-col>
  
            </v-row>
  
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { HTTP } from "@/http-common";
  
  export default {
    data() {
      return {
        dialog: false,
        is_confirm: false,
        confirm_dialog: false,
        card: null,
        btnLoading: false,
        error: "",
      };
    },
    methods: {
      open(card) {
        this.error = "";
        this.card = card;
  
        this.dialog = true;
      },
      confirm() {
        this.error = "";
        this.btnLoading = true;
  
        HTTP.delete( "v1/occasion_cards/"+ this.card.id)
          .then(() => {
            this.btnLoading = false;
            this.dialog = false;
            this.$successMessage = "Carte supprimée";
            this.$emit("refresh");
          })
          .catch((err) => {
            console.log(err);
            this.btnLoading = false;
  
            if (err.response.data.success === false) {
              this.error = "Impossible d'effectuer cette operation";
            }
          });
      },
      notConfirmed() {
        this.dialog = false;
      },
    },
  
    computed: {
      
    },
  };
  </script>