var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.item ? "Modifier une carte" : "Nouvelle carte")+" "),_c('v-spacer'),_c('v-btn',{staticClass:"v-btn--active",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-6",staticStyle:{"overflow-y":"scroll","max-height":"450px"}},[_c('v-select',{attrs:{"items":_vm.languages,"label":"Langue","item-value":"id","item-text":"name","outlined":"","error-messages":_vm.formErrors.language},on:{"input":function($event){_vm.formErrors && _vm.formErrors.language
                            ? (_vm.formErrors.language = '')
                            : false}},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}}),_c('v-select',{attrs:{"loading":_vm.categoriesLoading,"items":_vm.categories,"label":"Categories ","item-value":"id","item-text":"name","error-messages":_vm.formErrors.categories,"multiple":"","clearable":"","chips":"","small-chips":"","deletable-chips":"","outlined":""},on:{"input":function($event){_vm.formErrors && _vm.formErrors.categories
                            ? (_vm.formErrors.categories = '')
                            : false}},model:{value:(_vm.form.categories),callback:function ($$v) {_vm.$set(_vm.form, "categories", $$v)},expression:"form.categories"}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-file-input',{ref:"file",attrs:{"label":"Image *","accept":"image/png, image/jpeg, image/jpg, image/svg, .svg","show-size":"","suffix":"Image","chips":"","prepend-icon":"mdi-camera","error-messages":_vm.formErrors.image},on:{"change":($event) => this.form.image = $event,"focus":function($event){_vm.formErrors && _vm.formErrors.image
                                ? (_vm.formErrors.image = '')
                                : false}}}),(_vm.form.image)?_c('v-btn',{staticClass:"primary white--text ml-2",attrs:{"depressed":"","icon":"","small":""},on:{"click":function($event){return _vm.previewImage(_vm.form.image)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi mdi-eye")])],1):_vm._e()],1),_c('v-btn',{staticClass:"rounded-lg mt-4",attrs:{"depressed":"","loading":_vm.btnLoading,"large":"","block":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" Enregister ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }