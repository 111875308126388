<template>
    <div>
        <v-dialog v-model="dialog" max-width="600" persistent>
            <v-card>
                <v-card-title>
                    {{ "Filtrer les carte" }}
                    <v-spacer />
                    <v-btn icon class="v-btn--active" color="primary" @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-6" style="overflow-y: scroll; max-height: 450px">

                    <v-select v-model="filter.language" :items="languages" label="Langue" item-value="id" item-text="name"
                        outlined  ></v-select>


                    <v-select :loading="categoriesLoading" v-model="filter.categories" :items="categories" label="Categories "
                        item-value="id" item-text="name" multiple clearable chips small-chips
                        deletable-chips outlined>
                    </v-select>

                    <v-btn depressed :loading="btnLoading" large block color="primary" class="rounded-lg mt-4"
                        @click="save">
                        Filter
                    </v-btn>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { HTTP } from '@/http-common';
export default {
    props: ["filter"],
    data() {
        return {
            dialog: false,
           
            categories: [],
            categoriesLoading: false,
            languages: [
                {
                    id: null,
                    name: "Tous"
                },
                {
                    id: "fr",
                    name: "Francais"
                },
                {
                    id: "ar",
                    name: "Arabe"
                }
            ]
        }
    },
    methods: {
        open() {
            if (this.categories.length == 0) {
                this.fetchCategories();
            }
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        fetchCategories() {
            this.categoriesLoading = true;
            HTTP.get('v1/occasion_categories?size=1000').then((res) => {
                this.categories = res.data.data.data;

                this.categoriesLoading = false;
            }).catch((e) => {
                this.categoriesLoading = false;
                console.log(e);
            });
        },
        save() {
            this.$emit('handleFilter', this.filter);
            this.close();
        }
    },
    mounted() {

    }

}
</script>

<style lang="scss" scoped></style>