<template>
    <div>
        <v-dialog v-model="dialog" max-width="600" persistent>
            <v-card>
                <v-card-title>
                    {{ item ? "Modifier une carte" : "Nouvelle carte" }}
                    <v-spacer />
                    <v-btn icon class="v-btn--active" color="primary" @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-6" style="overflow-y: scroll; max-height: 450px">

                    <v-select v-model="form.language" :items="languages" label="Langue" item-value="id" item-text="name"
                        outlined @input="
                            formErrors && formErrors.language
                                ? (formErrors.language = '')
                                : false
                            " :error-messages="formErrors.language"></v-select>


                    <v-select :loading="categoriesLoading" v-model="form.categories" :items="categories" label="Categories "
                        item-value="id" item-text="name" @input="
                            formErrors && formErrors.categories
                                ? (formErrors.categories = '')
                                : false
                            " :error-messages="formErrors.categories" multiple clearable chips small-chips
                        deletable-chips outlined>
                    </v-select>

                    <div class="d-flex align-center">
                        <v-file-input label="Image *" accept="image/png, image/jpeg, image/jpg, image/svg, .svg" show-size
                            suffix="Image" @change="($event) => this.form.image = $event" @focus="
                                formErrors && formErrors.image
                                    ? (formErrors.image = '')
                                    : false
                                " ref="file" chips prepend-icon="mdi-camera" :error-messages="formErrors.image" />
                        <v-btn depressed icon small class="primary white--text ml-2" v-if="form.image"
                            @click="previewImage(form.image)">
                            <v-icon small>mdi mdi-eye</v-icon>
                        </v-btn>
                    </div>

                    <v-btn depressed :loading="btnLoading" large block color="primary" class="rounded-lg mt-4"
                        @click="save">
                        Enregister
                    </v-btn>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { HTTP } from '@/http-common';
export default {
    data() {
        return {
            dialog: false,
            item: null,
            btnLoading: false,
            form: {
                language: "fr",
                image: null,
                categories: []

            },
            formErrors: {},
            categories: [],
            categoriesLoading: false,
            languages: [
                {
                    id: "fr",
                    name: "Francais"
                },
                {
                    id: "ar",
                    name: "Arabe"
                }
            ]
        }
    },
    methods: {
        open(item) {
            this.item = item;
            if(item){
                this.form = {
                    language: item.language,
                    categories: item.categories.map((obj) => obj.id),
                    image: null
                }
            }
            if (this.categories.length == 0) {
                this.fetchCategories();
            }

            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        fetchCategories() {
            this.categoriesLoading = true;
            HTTP.get('v1/occasion_categories?size=1000').then((res) => {
                this.categories = res.data.data.data;

                this.categoriesLoading = false;
            }).catch((e) => {
                this.categoriesLoading = false;
                console.log(e);
            });
        },
        save() {
            this.formErrors = {};
            this.btnLoading = true;
            var formData = new FormData();
            Object.keys(this.form).forEach((key) => {
                formData.append(key, this.form[key]);
            });
            var url = '/v1/occasion_cards';
            if(this.item){
                formData.append("_method", "PUT");
                url+= "/"+this.item.id;
            }

            HTTP.post(url, formData).then((res) => {
                this.btnLoading = false;
                this.$successMessage = "Enregistrement avec succès"
                this.$refs.file.reset();
                this.$emit("refresh");
                this.close();
            }).catch((err) => {
                this.btnLoading = false;

                if (err.response && err.response.data && err.response.data.errors) {
                    this.formErrors = err.response.data.errors;
                } else {
                    this.$errorMessage = "Une erreur s'est produite";
                }
                console.log(err);
            })
        }
    },
    mounted() {

    }

}
</script>

<style lang="scss" scoped></style>